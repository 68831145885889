




















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import debounce from 'lodash.debounce'
import { feedQueryKeys, useFetchRssFeed } from '@/hooks/rssFeeds'
import moment from 'moment'
import { Action, Getter } from 'vuex-class'
import { computed, reactive, ref, SetupContext, watch } from '@vue/composition-api'
import { useGetPodcastSearch } from '@/hooks/podcast'
import get from 'lodash.get'
import { url } from 'inspector'
import { useCreateYoutubeFeed, useGetPublicFeed } from '@/hooks/youtube'
import { QueryClient, useQueryClient } from 'vue-query'
import { trackType, useGetFeedTracks } from '@/hooks/audio'
import PodcastEpisode from './PodcastEpisode.vue'

@Component({
  components: {
    PodcastEpisode,
  },
  setup(props: any, { root }: SetupContext) {
    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed({}, true, (data: any) => {})

    const searchQuery = ref('')
    const isSearchEnabled = computed(() => !!searchQuery.value)
    const { data: podcastResults, isFetching: isPodcastFetching } = useGetPodcastSearch(searchQuery, {
      enabled: true,
    })

    const queryClient = useQueryClient()
    const { mutate: getPublicFeed, data: publicFeedData, isLoading: isFeedLoading } = useGetPublicFeed()
    const { mutate: createFeed, data: createFeedData, isLoading: isCreateFeedLoading } = useCreateYoutubeFeed()

    watch(publicFeedData, (curVal: any) => {
      const payload = {
        rss: curVal.rss_url,
        imageUrl: curVal.imageUrl || get(curVal.episodes[0], 'imageUrl', null),
      }
      createFeed(payload)
    })
    watch(createFeedData, (curVal: any) => {
      selectedPodcastUid.value = curVal.uid
      queryClient.invalidateQueries(feedQueryKeys.YTFEED)
    })

    const selectedPodcastUid = ref('')
    const uid: any = computed(() => selectedPodcastUid.value)
    const isFeedEnabled: any = computed(() => !!selectedPodcastUid.value)
    const feedTrackParams: any = reactive({
      rssFeedUid: selectedPodcastUid,
      limit: 10,
      offset: 0,
      order: 'recent_first',
      filter: 'all',
      fromYoutube: true,
    })

    const { data: feedTracksUnpublishedData, isFetching: feedTracksUnpublishedLoading } = useGetFeedTracks(
      'UNPUBLISHED',
      uid,
      { enabled: isFeedEnabled },
      feedTrackParams
    )

    return {
      rssFeedsData,
      rssFeedLoading,
      searchQuery,
      podcastResults,
      isPodcastFetching,
      queryClient,
      getPublicFeed,
      publicFeedData,
      isFeedLoading,
      createFeed,
      isCreateFeedLoading,
      createFeedData,
      selectedPodcastUid,
      feedTracksUnpublishedData,
      feedTracksUnpublishedLoading,
    }
  },
})
export default class PodcastToVideo extends Vue {
  @Getter networkId!: any

  @Action importRssFeed!: any

  loadingText = 'Loading podcasts ...'

  podcastSearchText = ''
  isSearchingPodcasts: boolean = false
  rssFeedsData!: any
  rssFeedLoading!: boolean
  podcastSortOrder: string = 'latestCreated'

  searchQuery!: string
  podcastResults!: any
  isPodcastFetching!: boolean

  getPublicFeed!: any
  isFeedLoading!: boolean
  isCreateFeedLoading!: boolean
  queryClient!: QueryClient

  selectedPodcastUid!: string
  feedTracksUnpublishedData!: any
  feedTracksUnpublishedLoading!: boolean

  disableAll = false

  mounted() {
    if (this.$refs._podcastSearch !== undefined) {
      // @ts-ignore
      this.$refs._podcastSearch.focus()
    }
  }

  get podcasts() {
    return get(this.podcastResults, 'audioTracks.results', [])
  }

  get episodeList() {
    return get(this, 'feedTracksUnpublishedData', [])
  }

  get filteredList() {
    if (!this.rssFeedsData) return []
    const list = [...this.rssFeedsData.data]
    let filteredList = []
    switch (this.podcastSortOrder) {
      case 'latestCreated':
        filteredList = list.sort((a: any, b: any) => moment(a.createdOn).unix() - moment(b.createdOn).unix()).reverse()
        break
      case 'AZ':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1))
        break
      case 'ZA':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        break
      default:
        filteredList = list
    }

    return filteredList
  }

  get selectedPodcast() {
    return this.filteredList.filter((ep) => ep.uid === this.selectedPodcastUid).length
      ? this.filteredList.filter((ep) => ep.uid === this.selectedPodcastUid)[0]
      : false
  }

  debouncedSearch = debounce(() => {
    this.onPodcastSearch()
  }, 600)

  @Watch('podcastSearchText')
  handleReset() {
    if (this.podcastSearchText.length === 0) {
      this.searchQuery = ''
    }
  }

  async onPodcastSearch() {
    this.loadingText = 'Searching podcasts ...'
    this.searchQuery = this.podcastSearchText
  }

  async importPodcast(podcast: any) {
    this.loadingText = 'Importing podcast ...'
    if (podcast.url) {
      this.getPublicFeed({ url: podcast.url })
    } else if (podcast.rss_url) {
      this.getPublicFeed({ url: podcast.rss_url })
    }
    this.podcastSearchText = ''
    this.searchQuery = ''
  }

  selectRecentPodcast(podcast: any) {
    this.loadingText = 'Searching episodes ...'
    this.selectedPodcastUid = podcast.uid
  }

  handleBack() {
    this.selectedPodcastUid = ''
  }

  handleDisable(value: boolean) {
    this.disableAll = value
  }
}
