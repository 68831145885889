


























































import { tagQueryKeys } from '@/hooks/tag'
import Invalidate from '@/mixins/Invalidate'
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component
export default class ModalKenburns extends Mixins(Invalidate) {
  kenBurnZoomDirectionList = [
    'top',
    'left',
    'right',
    'bottom',
    'center',
    'topleft',
    'topright',
    'centerleft',
    'centerright',
    'bottomleft',
    'bottomright',
  ]
  kenBurnZoomDirection = this.kenBurnZoomDirectionList[7]
  kenBurnZoomEffect = [
    { value: 0.5, title: 'zoom in' },
    { value: -0.5, title: 'zoom out' },
  ]
  kenBurnZoomEffectValue = -0.5

  isLoading = false

  async applyEffect() {
    this.isLoading = true
    try {
      const effects = await this.$store.dispatch('createTagEffect', {
        zoomDirection: this.kenBurnZoomDirection,
        zoomValue: this.kenBurnZoomEffectValue,
        //   zoomDuration: removeFormatting(this.duration) / 1000,
        zoomDuration: 5,
      })
      this.queryClient.invalidateQueries([tagQueryKeys.TAG_EFFECTS, effects.id])
      this.$emit('selectZoomEffect', effects.id)

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
    }
  }
}
