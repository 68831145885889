














































import { useCreateAudioTrack, useCreateAudioUploadUrl, useGetAudioUploadStatus } from '@/hooks/audio'
import { useUploadImage } from '@/hooks/image'
import { formatTimeAgo, readableMillisToSS } from '@/utils/time'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { computed, ref, SetupContext } from '@vue/composition-api'
import { uploadStatus } from '../Publish/publish'
import { Action, Getter } from 'vuex-class'

@Component({
  setup(props: any, context: SetupContext) {
    const { mutate: uploadImage, isLoading: isImageUploading, data: imageResp } = useUploadImage()
    const { mutate: createAudioUploadUrl, isLoading: isUrlUploading, data: uploadUrlResp } = useCreateAudioUploadUrl()
    const { mutate: createAudio, isLoading: isAudioTrackCreating } = useCreateAudioTrack((data: any) => {
      context.root.$router.push(`/preview/${data.uid}`)
    })

    const uploadData: any = ref(uploadUrlResp)
    const imageData: any = ref(imageResp)
    const isUploadIdExist = computed(() => !!uploadData.value?.uploadId)
    const uploadId = computed(() => uploadData.value?.uploadId)
    const interval: any = ref(false)

    const { isFetching: isStatusLoading } = useGetAudioUploadStatus(
      uploadId,
      { enabled: isUploadIdExist },
      interval,
      (data: any) => {
        if ([uploadStatus.QUEUED, uploadStatus.STARTED].includes(data.importStatus)) {
          interval.value = 6000
        } else if (data.importStatus === uploadStatus.FINISHED || data.importStatus === null) {
          const payload: any = {
            category: 'TRACK',
            ytFeedUid: props.podcast.ytFeedUid,
            name: props.audioTrack.name,
            description: props.audioTrack.description,
            explicitContent: props.audioTrack.explicitContent,
            language: props.audioTrack.language || 'en-US',
            public: true,
          }

          payload['uploadId'] = uploadId.value
          payload['guid'] = props.audioTrack.guid
          payload['imageId'] = imageData.value.id

          interval.value = false
          createAudio(payload)
        } else if (data.importStatus === uploadStatus.FAILED) {
          interval.value = false
          context.root.$store.dispatch('pushNotification', {
            text: 'Oops! Processing failed. try again later',
            type: 'ERROR',
          })
        }
      }
    )

    return {
      interval,
      isStatusLoading,
      isAudioTrackCreating,
      isImageUploading,
      isUrlUploading,
      uploadImage,
      createAudioUploadUrl,
    }
  },
})
export default class PodcastEpisode extends Vue {
  @Prop() podcast!: any
  @Prop() audioTrack!: any
  @Prop() disableAll!: boolean

  @Getter networkId!: any
  @Action checkAudioUpload!: any

  interval!: any
  isStatusLoading!: boolean
  isAudioTrackCreating!: boolean
  isImageUploading!: boolean
  isUrlUploading!: boolean
  uploadImage!: any
  createAudioUploadUrl!: any

  checkAudioLoading = false

  get audioPreviewImage() {
    return this.audioTrack && this.audioTrack.imageUrl ? this.audioTrack.imageUrl : this.podcast.imageUrl
  }

  get audioDate() {
    return formatTimeAgo(this.audioTrack.publishedOn)
  }

  get duration() {
    return this.audioTrack.durationMillis ? readableMillisToSS(this.audioTrack.durationMillis) : ''
  }

  @Watch('isStatusLoading')
  @Watch('isAudioTrackCreating')
  @Watch('isImageUploading')
  @Watch('isUrlUploading')
  @Watch('interval')
  @Watch('checkAudioLoading')
  handleDisable() {
    if (
      this.isStatusLoading ||
      this.isImageUploading ||
      this.isUrlUploading ||
      this.isAudioTrackCreating ||
      this.checkAudioLoading ||
      !!this.interval
    ) {
      this.$emit('handleDisable', true)
    } else {
      this.$emit('handleDisable', false)
    }
  }

  async createVideo() {
    try {
      this.checkAudioLoading = true
      const params = new URLSearchParams()
      params.append('by_guid', this.audioTrack.guid)
      let payload = {
        networkId: this.networkId,
        params: params,
      }
      const res = await this.checkAudioUpload(payload)
      if (res.length) {
        this.$router.push(`/preview/${res[0].uid}`)
      } else {
        this.uploadImage({
          url: this.audioPreviewImage,
        })
        this.createAudioUploadUrl({
          url: this.audioTrack.audioUrl,
          category: 'TRACK',
        })
      }
      this.checkAudioLoading = false
    } catch (error) {
      this.checkAudioLoading = false
    }
  }
}
