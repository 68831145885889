var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-column mt-3 justify-center items-center silver pa3 pa4-ns tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
    },
    [
      _vm.selectedPodcastUid
        ? _c("div", { staticStyle: { "min-height": "45rem" } }, [
            _vm.feedTracksUnpublishedLoading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 flex flex-column items-center justify-center",
                    staticStyle: { height: "20rem" },
                  },
                  [
                    _c("div", { staticClass: "loader" }),
                    _c("div", { staticClass: "mt3 white" }, [
                      _vm._v(_vm._s(_vm.loadingText)),
                    ]),
                  ]
                )
              : _vm.episodeList.length
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "flex items-center pointer dim mb3",
                      on: { click: _vm.handleBack },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons f3 light-gray mr2 v-btm",
                        },
                        [_vm._v("arrow_back")]
                      ),
                      _c("span", { staticClass: "light-gray fw1" }, [
                        _vm._v("Search different podcast"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "overflow-y-scroll scrollbar",
                      staticStyle: { "max-height": "45rem" },
                    },
                    _vm._l(_vm.episodeList, function (episode) {
                      return _c(
                        "div",
                        { key: episode.uid },
                        [
                          _c("PodcastEpisode", {
                            attrs: {
                              podcast: _vm.selectedPodcast,
                              disableAll: _vm.disableAll,
                              audioTrack: episode,
                            },
                            on: { handleDisable: _vm.handleDisable },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _c(
            "div",
            { staticClass: "w-100", staticStyle: { "min-height": "40rem" } },
            [
              _vm._m(0),
              _c("div", { staticClass: "flex flex-column mb3" }, [
                _c("div", { staticClass: "flex items-center mv2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.podcastSearchText,
                        expression: "podcastSearchText",
                      },
                    ],
                    ref: "_podcastSearch",
                    staticClass:
                      "input-reset bg-adori-gray bn h-50 light-gray pa2 pa3-ns br3 br--left w-100",
                    attrs: {
                      type: "search",
                      placeholder:
                        "Search for your podcasts or paste rss feed URL",
                      autofocus: "",
                    },
                    domProps: { value: _vm.podcastSearchText },
                    on: {
                      keyup: function ($event) {
                        return _vm.debouncedSearch()
                      },
                      reset: _vm.handleReset,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.podcastSearchText = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass:
                        "bg-adori-red bn light-gray flex justify-center items-center br3 br--right ph2 ph3-ns mb-height",
                      class: _vm.isPodcastFetching ? "o-50" : "",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onPodcastSearch()
                        },
                      },
                    },
                    [
                      !_vm.isPodcastFetching
                        ? _c("i", { staticClass: "f4 f3-ns material-icons" }, [
                            _vm._v("search"),
                          ])
                        : _c("div", { staticClass: "loader loader-inline" }),
                    ]
                  ),
                ]),
                _vm.isFeedLoading ||
                _vm.isCreateFeedLoading ||
                _vm.isPodcastFetching ||
                _vm.rssFeedLoading
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-100 flex flex-column items-center justify-center",
                        staticStyle: { height: "20rem" },
                      },
                      [
                        _c("div", { staticClass: "loader white" }),
                        _c("div", { staticClass: "mt3 white" }, [
                          _vm._v(_vm._s(_vm.loadingText)),
                        ]),
                      ]
                    )
                  : _vm.podcasts.length
                  ? _c(
                      "div",
                      {
                        staticClass: "w-100 overflow-y-scroll scrollbar",
                        staticStyle: { "max-height": "40rem" },
                      },
                      _vm._l(_vm.podcasts, function (podcast) {
                        return _c(
                          "div",
                          {
                            key: podcast.id,
                            staticClass:
                              "mb3 pa3 bg-adori-dark-gray br2 ba b--dark-gray pointer dim",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                on: {
                                  click: function ($event) {
                                    return _vm.importPodcast(podcast)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr3 flex-shrink-0",
                                    staticStyle: { "max-width": "120px" },
                                  },
                                  [
                                    podcast.image
                                      ? _c("img", {
                                          staticClass: "w-100 h-100 cover br1",
                                          attrs: {
                                            src: podcast.image,
                                            alt: podcast.title,
                                          },
                                        })
                                      : podcast.imageUrl
                                      ? _c("img", {
                                          staticClass: "w-100 h-100 cover br1",
                                          attrs: {
                                            src: podcast.imageUrl,
                                            alt: podcast.title,
                                          },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 h-100 flex items-center justify-center bg-adori-gray br1",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons silver",
                                              },
                                              [_vm._v("mic")]
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-grow-1 tl overflow-hidden",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "mt0 mb1 f5 silver" },
                                      [_vm._v(_vm._s(podcast.title))]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ma0 mb1 f6 silver o-70" },
                                      [_vm._v("by " + _vm._s(podcast.author))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-wrap mb1" },
                                      _vm._l(
                                        podcast.categories,
                                        function (category, idx) {
                                          return _c(
                                            "span",
                                            {
                                              key: idx,
                                              staticClass:
                                                "mr1 mb1 pa1 f7 bg-dark-gray silver br1",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(category) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "ma0 silver o-70 f6 lh-copy multiline-truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(podcast.description) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm.podcastSearchText.length === 0 && !_vm.selectedPodcast
                  ? _c(
                      "div",
                      {
                        staticClass: "w-100 scrollbar",
                        staticStyle: { "max-height": "40rem" },
                      },
                      [
                        _vm.filteredList && _vm.filteredList.length !== 0
                          ? _c("div", { staticClass: "mt4 mb2 f5 f4-ns" }, [
                              _vm._v("Recently used podcast"),
                            ])
                          : _vm._e(),
                        _vm.filteredList && _vm.filteredList.length !== 0
                          ? _c(
                              "div",
                              { staticClass: "pod-grid" },
                              _vm._l(_vm.filteredList, function (key) {
                                return _c(
                                  "div",
                                  {
                                    key: key.uid,
                                    staticClass:
                                      "pointer relative flex items-center justify-center dim",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "aspect-ratio aspect-ratio--1x1 w-100",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRecentPodcast(key)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "aspect-ratio--object flex items-center justify-center",
                                          },
                                          [
                                            _vm._m(1, true),
                                            key.thumbnail || key.imageUrl
                                              ? _c("img", {
                                                  staticClass:
                                                    "w-100 h-100 cover o-100 absolute z-999 br3",
                                                  attrs: {
                                                    src:
                                                      key.thumbnail ||
                                                      key.imageUrl,
                                                    alt: "Podcast thumbnail",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "absolute bottom-0 left-0 right-0 pa1 bg-black-60 white tc truncate br--bottom br2 f7 f6-ns dn",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      key.name ||
                                                        "Unnamed Podcast"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "light-gray ph2 ph3-ns pv2 f5 f4-ns db b-color" },
      [
        _c("i", { staticClass: "material-icons light-gray mr2 v-btm" }, [
          _vm._v(" mic "),
        ]),
        _vm._v("Podcast to Video\n    "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "absolute flex items-center justify-center w-100 h-100",
        staticStyle: { padding: "0.2px" },
      },
      [
        _c(
          "i",
          {
            staticClass:
              "material-icons dark-gray flex items-center justify-center dotted ba b--dashed",
            staticStyle: { width: "97%", height: "97%" },
          },
          [_vm._v("music_note")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }