var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
    },
    [
      !_vm.enableImageGallery
        ? _c(
            "div",
            { staticClass: "mb-w-50" },
            [
              _vm._m(0),
              _c("div", { staticClass: "pb gray f6 flex items-center" }, [
                _vm._v("Video title (required)"),
              ]),
              _c("div", { staticClass: "relative flex mb2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.videoTitle,
                      expression: "videoTitle",
                    },
                  ],
                  ref: "_input",
                  staticClass:
                    "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1 mb3",
                  attrs: {
                    type: "text",
                    placeholder: "Type your title for video here",
                  },
                  domProps: { value: _vm.videoTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.videoTitle = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 br2 flex flex-column items-center justify-center b--light-gray b--dashed bw1 pv2 mr3 pointer",
                    class:
                      _vm.isUploadingAudio || _vm.dropped
                        ? "not-allowed o-20"
                        : _vm.bgDragColor
                        ? "dark-fill"
                        : "link",
                    on: {
                      dragover: [
                        function ($event) {
                          $event.preventDefault()
                        },
                        function ($event) {
                          _vm.bgDragColor = true
                        },
                      ],
                      dragleave: function ($event) {
                        _vm.bgDragColor = false
                      },
                      drop: function ($event) {
                        $event.preventDefault()
                        return _vm.dropFile($event)
                      },
                      click: function ($event) {
                        _vm.isUploadingAudio || _vm.dropped
                          ? ""
                          : _vm.handleUploadAudio()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons f2 silver mb2" }, [
                      _vm._v("audio_file"),
                    ]),
                    _c("div", { staticClass: "f4 silver" }, [
                      _vm._v("Upload audio"),
                    ]),
                    _c("div", { staticClass: "f6 silver mt1" }, [
                      _vm._v("Supports: mp3, wav, ogg"),
                    ]),
                    _vm.selectedTextFile
                      ? _c(
                          "span",
                          {
                            staticClass: "mt2 gray overflow-y-hidden truncate",
                            staticStyle: { "max-width": "200px" },
                          },
                          [_vm._v(_vm._s(_vm.selectedFileName))]
                        )
                      : _vm._e(),
                    _vm.selectedTextFile
                      ? _c("span", { staticClass: "f6 gray" }, [
                          _vm._v("(" + _vm._s(_vm.selectedFileSize) + ")"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.hasPreviewImage
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-100 br2 flex flex-column items-center justify-center b--light-gray b--dashed bw1 pointer relative hide-child",
                        staticStyle: { height: "220px" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-height": "100%" },
                          attrs: { src: _vm.imageUrl, alt: "" },
                        }),
                        _c(
                          "i",
                          {
                            staticClass:
                              "absolute material-icons light-gray f3 child",
                            staticStyle: { top: "-10px", right: "-10px" },
                            on: { click: _vm.removeImage },
                          },
                          [_vm._v("cancel")]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "w-100 br2 flex flex-column items-center justify-center b--light-gray b--dashed bw1 pv5 pointer",
                        on: {
                          click: function ($event) {
                            _vm.showContentModal = true
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons f2 silver mb2" },
                          [_vm._v("image")]
                        ),
                        _c("div", { staticClass: "f4 silver" }, [
                          _vm._v("Add Thumbnail"),
                        ]),
                        _c("div", { staticClass: "f6 silver mt1" }, [
                          _vm._v("Supports: jpeg,png"),
                        ]),
                      ]
                    ),
              ]),
              _c(
                "div",
                { staticClass: "flex justify-center w-100 items-center" },
                [
                  _vm.uploadProgress < 100 && _vm.uploadProgress > 0
                    ? _c(
                        "div",
                        { staticClass: "pt3 flex flex-column items-center" },
                        [
                          _c("progress", {
                            attrs: { max: "100" },
                            domProps: { value: _vm.uploadProgress },
                          }),
                          _c("div", { staticClass: "f6 silver pt2" }, [
                            _vm._v(
                              "Uploading media: " +
                                _vm._s(_vm.uploadProgress + "%")
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c("BaseButtonRed", {
                staticClass: "gen-btn mt3 w-100",
                attrs: {
                  text: "Create Video",
                  onClick: _vm.uploadEpisode,
                  loading: _vm.uploadLoader,
                  disabled:
                    !_vm.selectedTextFile ||
                    !_vm.selectedImageSrc ||
                    !_vm.videoTitle ||
                    _vm.uploadLoader,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showContentModal
        ? _c("ModalContent", {
            attrs: {
              context: _vm.selectedContext,
              data: [],
              scene: _vm.selectedScene,
              isSowVideo: _vm.isSowVideo,
            },
            on: {
              closeModal: function ($event) {
                _vm.showContentModal = false
              },
              "load-image": _vm.handleContentLoad,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "light-gray ph3 pv2 f4 db b-color" }, [
      _c("i", { staticClass: "material-icons light-gray mr2 v-btm" }, [
        _vm._v(" audiotrack "),
      ]),
      _vm._v("Audio to Video\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }