var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-between items-center white mb4 relative" },
    [
      _c("div", { staticClass: "w-10 f6" }, [
        _vm._v("scene " + _vm._s(_vm.scene.index + 1)),
      ]),
      _c("div", { staticClass: "hide-child w-100 mr2 pr3" }, [
        _c("textarea", {
          staticClass:
            "db bg-adori-light-gray b--adori-gray ba br2 pl3 pr4 pv3 gray f6 white w-100 mh2 mt1 scrollbar",
          attrs: {
            id: "blog_scenes_" + _vm.scene.index + "_tour_step_4",
            type: "text",
            placeholder: "",
          },
          domProps: { value: _vm.sceneText },
          on: {
            input: function ($event) {
              return _vm.debouncedText($event.target.value)
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "absolute custom-ai-pos child",
            attrs: { id: "blog_scenes_" + _vm.scene.index + "_tour_step_8" },
          },
          [
            _c("img", {
              staticClass: "pointer ai-icon",
              attrs: { src: require("@/assets/ai.svg") },
              on: { click: _vm.toggleAIPanel },
            }),
          ]
        ),
        _c(
          "i",
          {
            staticClass:
              "material-icons adori-gray v-mid pointer f3 ml3 grow absolute custom-minus-pos child",
            attrs: { id: "blog_scenes_" + _vm.scene.index + "_tour_step_6" },
            on: { click: _vm.handleRemoveScene },
          },
          [_vm._v("\n      cancel\n    ")]
        ),
      ]),
      _vm.scene.image
        ? _c("div", { staticClass: "w-13 relative hide-child" }, [
            _c("img", {
              staticClass: "br2 pointer img-size",
              attrs: {
                id: "blog_scenes_" + _vm.scene.index + "_tour_step_7",
                src: _vm.scene.image,
              },
            }),
            _c("div", {
              staticClass: "bg-black-50 absolute top-0 child pointer",
              staticStyle: { width: "100%", height: "100%" },
              on: { click: _vm.toggleBlogImage },
            }),
            _c(
              "i",
              {
                staticClass:
                  "material-icons adori-gray v-mid pointer f3 ml3 grow absolute custom-remove-pos child",
                on: { click: _vm.handleRemoveSceneImage },
              },
              [_vm._v("\n      cancel\n    ")]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons adori-gray v-mid pointer f3 ml3 grow absolute bottom-0 right-0 child",
                on: {
                  click: function ($event) {
                    return _vm.$emit("viewImages", _vm.scene.image)
                  },
                },
              },
              [_vm._v("\n      visibility\n    ")]
            ),
          ])
        : _vm.scene.video
        ? _c("div", { staticClass: "w-13 relative hide-child" }, [
            _c(
              "video",
              {
                staticClass: "br2 pointer",
                attrs: {
                  id: "blog_scenes_" + _vm.scene.index + "_tour_step_7",
                  poster: _vm.scene.video.thumbNail,
                  width: "100",
                  muted: "",
                },
                domProps: { muted: true },
                on: {
                  mouseover: _vm.handleOver,
                  mouseout: _vm.handleOut,
                  click: _vm.toggleBlogImage,
                },
              },
              [
                _c("source", {
                  attrs: {
                    src:
                      _vm.scene.video.previewUrl || _vm.scene.video.originalUrl,
                    type: "video/mp4",
                  },
                }),
                _vm._v(
                  "\n      Your browser does not support the video tag.\n    "
                ),
              ]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons adori-gray v-mid pointer f3 ml3 grow absolute custom-remove-pos child",
                on: { click: _vm.handleRemoveSceneImage },
              },
              [_vm._v("\n      cancel\n    ")]
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "div-plus",
              on: {
                mouseenter: function ($event) {
                  _vm.activeHover = true
                },
                mouseleave: function ($event) {
                  _vm.activeHover = false
                },
                click: _vm.toggleBlogImage,
              },
            },
            [
              _c("div", { staticClass: "pointer" }, [
                _vm.activeHover
                  ? _c("div", { staticClass: "overlay" }, [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons white v-mid pointer ml3 o-icon",
                        },
                        [_vm._v(" add ")]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "i",
                  {
                    staticClass:
                      "material-icons white v-mid pointer ml3 o-icon",
                  },
                  [_vm._v(" image ")]
                ),
              ]),
            ]
          ),
      _c(
        "i",
        {
          staticClass:
            "material-icons adori-gray v-mid pointer f3 ml3 grow absolute custom-add-pos",
          attrs: { id: "blog_scenes_" + _vm.scene.index + "_tour_step_5" },
          on: { click: _vm.handleAddScene },
        },
        [_vm._v("\n    add_circle\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }