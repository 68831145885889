var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bb b--adori-gray pb3 darker-hover relative" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap justify-between ph2 pt3 link darker-hover br2 relative",
          class: _vm.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(
            _vm.podcast ? _vm.podcast.uid : ""
          ),
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start" },
            [
              _c("BaseThumbnailEpisode", {
                staticClass: "mr3",
                attrs: { src: _vm.audioPreviewImage },
              }),
              _c(
                "div",
                {
                  staticClass: "flex items-center justify-between mr2",
                  staticStyle: { width: "650px" },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "f4 overflow-y-hidden truncate light-gray flex",
                        staticStyle: { width: "485px" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.audioTrack
                                ? _vm.audioTrack.name
                                : "Loading audio details..."
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "flex f6 mt2" }, [
                      _vm._v(_vm._s(_vm.duration)),
                    ]),
                  ]),
                  _c("div", { staticClass: "f6 gray" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.audioTrack
                            ? _vm.audioDate
                            : "Loading audio details..."
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "flex items-center" }, [
            _c(
              "div",
              {
                staticClass: "flex justify-end items-center",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _vm.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(
                  _vm.podcast ? _vm.podcast.uid : ""
                )
                  ? _c("BaseButtonRed", {
                      staticClass: "mr3 mb0 w-fixed",
                      attrs: {
                        text: "Create Video",
                        className:
                          "flex justify-center items-center f6  br2 ba b--adori-red light-gray bg-dark-gray btn-shadow",
                        onClick: _vm.createVideo,
                        disabled: _vm.disableAll,
                        loading:
                          _vm.isStatusLoading ||
                          _vm.isImageUploading ||
                          _vm.isUrlUploading ||
                          _vm.isAudioTrackCreating ||
                          !!_vm.interval ||
                          _vm.checkAudioLoading,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }