
































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { autoPickSettingList, videoResolutionList } from '../AI/setting'
import { Action, Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import { uploadStatus } from '../Publish/publish'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'
import ModalContent from '@/components/Modals/ModalContent.vue'

@Component({
  components: { ModalContent },
})
export default class UploadAudio extends Mixins(Invalidate) {
  @Getter selectedFile!: any
  @Getter networkId!: any

  @Action createAudioTrack!: any
  @Action uploadAudio!: any

  videoTitle = ''
  bgDragColor = false
  isUploadingAudio = false
  dropped = false
  selectedAutoPickSetting: string = 'videos'
  selectedOrientation = 'landscape'
  selectedScriptLanguage = 'English'
  imageUrl = ''
  finalProgress = 0

  enableImageGallery = false
  uploadLoader = false
  selectedImageSrc = ''
  selectedImageId: string = ''
  hasClickedImage: boolean = false
  imgLoader = false

  selectedTextFile: any = null

  showContentModal = false
  selectedContext = 'BLOG_TEXT'
  selectedScene = {}
  isSowVideo = false

  get autoPickData() {
    return autoPickSettingList
  }

  get videoResolutionData() {
    return videoResolutionList
  }

  get selectedFileName() {
    return this.selectedTextFile && this.selectedTextFile.name
  }

  get selectedFileSize() {
    if (this.selectedTextFile) {
      let size = this.selectedTextFile.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      return Math.round(size * 100) / 100 + ' ' + fSExt[i]
    }
  }
  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get hasCompletedUpload() {
    if (this.uploadProgress === 100) return true
  }

  get languagesMenu() {
    let menu = [
      {
        name: 'English',
        onClick: () => {
          this.selectedScriptLanguage = 'English'
        },
      },
      {
        name: 'Spanish',
        onClick: () => {
          this.selectedScriptLanguage = 'Spanish'
        },
      },
      {
        name: 'Portuguese',
        onClick: () => {
          this.selectedScriptLanguage = 'Portuguese'
        },
      },
      {
        name: 'Polish',
        onClick: () => {
          this.selectedScriptLanguage = 'Polish'
        },
      },
      {
        name: 'Italian',
        onClick: () => {
          this.selectedScriptLanguage = 'Italian'
        },
      },
      {
        name: 'French',
        onClick: () => {
          this.selectedScriptLanguage = 'French'
        },
      },
      {
        name: 'German',
        onClick: () => {
          this.selectedScriptLanguage = 'German'
        },
      },
      {
        name: 'Hindi',
        onClick: () => {
          this.selectedScriptLanguage = 'Hindi'
        },
      },
    ]

    return menu
  }

  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  @Watch('uploadProgress')
  handleUpload() {
    if (this.uploadProgress === 100) {
      this.createTrack()
    }
  }

  @Watch('selectedFile')
  handleHasFileSelected(e: any) {
    if (!(this.selectedFile instanceof FileList)) {
      this.selectedTextFile = this.selectedFile
    }
  }

  showImageGallery() {
    if (this.uploadLoader) return
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
  }

  fileType(file: any) {
    const filename = file.name
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  dropFile(event: any) {
    this.bgDragColor = false
    const file = event.dataTransfer.files[0]
    if (!['mp3', 'wav', 'ogg', 'x-wav'].includes(this.fileType(file))) {
      this.$store.dispatch('pushNotification', {
        text: 'File format is not supported! Please choose different file format',
        type: 'WARNING',
      })
      return
    }
    if (this.bytesToMegaBytes(file.size) > 500) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload video lesser than 500MB',
        type: 'WARNING',
      })
      return
    }
    this.dropped = true
    this.$store.commit('setSelectedFile', event.dataTransfer.files[0])
    this.dropped = false
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadRawAudio')
  }

  async uploadEpisode() {
    this.uploadLoader = true
    this.$store.commit('setSelectedFile', this.selectedTextFile)
    try {
      await this.uploadAudio()
    } catch (error) {
      this.uploadLoader = false
    }
  }

  async createTrack() {
    this.uploadLoader = true
    try {
      await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)

      const poll = setInterval(async () => {
        const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)
        this.finalProgress = res.uploadProgress

        if (res.processingStatus === uploadStatus.FINISHED) {
          clearInterval(poll)
          const img: any = await AdoriService.uploadImage(this.networkId, {
            url: this.selectedImageSrc,
          })

          const payload = {
            category: 'TRACK',
            ytFeedUid: null,
            name: this.videoTitle,
            description: '',
            language: 'en-US',
            public: true,
            uploadId: this.$store.getters.audioUploadId,
            imageId: img.id,
            keywords: [],
          }

          const audioTrack = await this.createAudioTrack(payload)
          this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)

          await this.$store.dispatch('clearAudioUploader')
          this.uploadLoader = false

          this.$router.push(`/preview/${audioTrack.uid}`)
        }
        if (res.processingStatus === uploadStatus.FAILED) {
          clearInterval(poll)
          this.$store.dispatch('pushNotification', {
            text: 'Upload Failed',
            type: 'ERROR',
          })
          this.uploadLoader = false
          this.$store.dispatch('closeModal')
        }
      }, 5000)
    } catch (e) {
      this.$store.dispatch('pushNotification', {
        text: 'Upload Failed',
        type: 'ERROR',
      })
      this.uploadLoader = false
    }
  }

  handleContentLoad(image: any) {
    this.showContentModal = false
    this.imageUrl = image.previewUrl
    this.selectedImageSrc = image.url
  }
  removeImage() {
    this.imageUrl = ''
    this.selectedImageSrc = ''
  }
}
