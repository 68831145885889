import AdoriServiceV6 from '@/services/adori_v6'
import AdoriService from '@/services/adori'
import store from '@/store'
import { isWebLink } from '@/utils/misc'
import { queryDefaults } from '@/utils/query.config'
import { useQuery } from 'vue-query'

// Query Keys
export enum podQueryKeys {
  PODINDEX = 'PODINDEX',
}

// Queries
const getPodIndexRes = (networkId: string, query: string): any => {
  const webLink = isWebLink(query)
  return webLink
    ? AdoriService.parseRssUrl({
        rss: query,
      })
    : AdoriServiceV6.podIndexSearch(networkId, query)
}
// Hooks
export const useGetPodcastSearch = (query: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([podQueryKeys.PODINDEX, networkId, query], () => getPodIndexRes(networkId, query.value), {
    keepPreviousData: true,
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
    select: (data: any) => {
      const isName = data.hasOwnProperty('audioCollections')
      return isName
        ? data
        : {
            audioCollections: {},
            audioTracks: {
              results: [data],
            },
            curatedLists: {},
          }
    },
  })
}
