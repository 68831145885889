var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Kenburns Effect"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "w-100" }, [
          _c("div", { staticClass: "flex mt2 justify-between items-center" }, [
            _c("div", { staticClass: "white f6 mr3" }, [_vm._v("Direction")]),
            _c("div", { staticClass: "w-50" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.kenBurnZoomDirection,
                      expression: "kenBurnZoomDirection",
                    },
                  ],
                  ref: "kenBurnZoomDirection",
                  staticClass:
                    "db bg-adori-light-gray white ba b--gray br2 f6 w-100 h2",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kenBurnZoomDirection = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.kenBurnZoomDirectionList, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item } },
                    [
                      _vm._v(
                        "\n              " + _vm._s(item) + "\n            "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex mt2 justify-between items-center" }, [
            _c("div", { staticClass: "white f6 mr3" }, [_vm._v("Zoom Effect")]),
            _c("div", { staticClass: "w-50" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.kenBurnZoomEffectValue,
                      expression: "kenBurnZoomEffectValue",
                    },
                  ],
                  ref: "kenBurnZoomEffectValue",
                  staticClass:
                    "db bg-adori-light-gray white ba b--gray br2 f6 w-100 h2",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kenBurnZoomEffectValue = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.kenBurnZoomEffect, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.value } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.title) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("base-button-red", {
            staticClass: "w-100 mt3",
            attrs: {
              text: "Apply effect",
              disabled: _vm.isLoading,
              loading: _vm.isLoading,
              onClick: _vm.applyEffect,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }